import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import axios from '@utils/request'
import { ISyncAuthenticateURLPayload } from '@app-store/slices/calendar/typings'
import { rootActions } from '@app-store/slices'

interface TaskAction extends Action {
    payload: ISyncAuthenticateURLPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.calendar.syncAuthenticateUrl

function* syncAuthenticateURLSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/meeting/sync/calendar/auth-url?sourceType=${payload.sourceType}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            if (typeof payload?.sagaCB?.onSuccess === 'function') {
                payload?.sagaCB?.onSuccess(response.result)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* syncAuthenticateURLSaga(): any {
    yield takeLatest<TaskAction>(onRequest, syncAuthenticateURLSagaTask)
}

export default syncAuthenticateURLSaga
